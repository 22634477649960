.content {
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  background-color: white;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 4px 4px 15px lightgrey;
}
.margin-top {
  margin-top: 10px !important;
}

.padding {
  padding-top: 30px;
}

.right-align-input {
  text-align: right;
}

.list {
  list-style-type: none;
}

.generate-pdf-button {
  padding: 200px;
  margin: auto;
}

.bold {
  font-weight: bold !important;
  color: blue;
}
